import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { UserSatisfactionStepComponent } from '@common/types/ClaimWorkflow';

import { getLocalStorage, setLocalStorage } from '../../../utils';
import { StepComponentFC, StepComponentSharedProps } from '../types/stepComponentTypes';
import EmojiSlider from './components/EmojiSlider/EmojiSlider';
import StarRating from './components/StarRating';

const BUTTON_LEVELS = [
  {
    value: 20,
    icon: '🤬',
  },
  {
    value: 40,
    icon: '🙁',
  },
  {
    value: 60,
    icon: '😐',
  },
  {
    value: 80,
    icon: '🙂',
  },
  {
    value: 100,
    icon: '🥰',
  },
];
interface UserSatisfactionRatingProps
  extends StepComponentSharedProps<
    UserSatisfactionStepComponent,
    number | null
  > {}
const UserSatisfactionRating: StepComponentFC<UserSatisfactionRatingProps> = ({
  step_component,
  updateValue,
  className,
}) => {
  const BUTTON_TYPE = 'buttons';
  const STARS_TYPE = 'stars';
  const SLIDER_TYPE = 'emojiSlider';
  const ratingComponent = step_component.rating_component || BUTTON_TYPE;

  const onSubmit = (value: number) => {
    window.analytics.track('User submitted rating', {
      ratingComponent: ratingComponent,
      value,
    });

    updateValue(step_component.field, value);
  };

  const containerClasses = classNames('flex justify-center', className);

  return (
    <div className={containerClasses}>
      {ratingComponent === STARS_TYPE ? (
        <StarRating
          onSubmit={onSubmit}
          valueList={BUTTON_LEVELS.map(({ value }) => value)}
        />
      ) : null}

      {ratingComponent === SLIDER_TYPE ? (
        <EmojiSlider
          emojiList={BUTTON_LEVELS.map(({ icon }) => icon)}
          stopPoints={[20, 40, 60, 80, 101]}
          minValue={1}
          maxValue={100}
          onSubmit={onSubmit}
        />
      ) : null}

      {ratingComponent === BUTTON_TYPE
        ? BUTTON_LEVELS.map(({ value, icon }) => {
            return (
              <button
                className="p-2 m-1 md:p-3 md:m-2 border-cool-gray-300 border-solid border-2 transition ease-in-out duration-150 cursor-pointer rounded-md flex flex-col items-center justify-center hover:shadow hover:bg-cool-gray-100 focus:outline-none"
                style={{ flexBasis: 20 }}
                onClick={() => {
                  onSubmit(value);
                }}
              >
                <div className="text-3xl md:text-4xl leading-none">
                  <div className="py-1 flex flex-col justify-center">
                    {icon}
                  </div>
                </div>
              </button>
            );
          })
        : null}
    </div>
  );
};

export default UserSatisfactionRating;
