import classNames from 'classnames';
import React from 'react';
import useMeasure from 'react-use/lib/useMeasure';
import useWindowSize from 'react-use/lib/useWindowSize';

import { Transition } from '@headlessui/react';

import Button from '../Button';

interface ModalProps {
  /** Determines if the modal is visible or not */
  isShowing: boolean;
  /** String that displays on top of the modal */
  title: string;
  /** Function responsible for closing the modal */
  onClose: () => void;
  /** Function responsible for what happens when the save changes button is clicked */
  onSubmit?: () => void;
  /** Use a more subtle close button at the bottom of the modal */
  subtleCloseButton?: boolean;
  /** Don't show any action buttons at the bottom of the modal */
  hideActionButtons?: boolean;
  /** Larger variant */
  fullWidth?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isShowing,
  title,
  onClose,
  onSubmit,
  subtleCloseButton,
  hideActionButtons,
  fullWidth,
  children,
}) => {
  const { height: windowHeight } = useWindowSize();
  const [ref, { height: modalHeight }] = useMeasure();
  const isLongModalMode = modalHeight > windowHeight - 100;

  const inner = (
    <div
      className={
        isShowing
          ? classNames(
              'px-4 pb-6 sm:inset-0 sm:p-0 z-30',
              isLongModalMode
                ? 'relative sm:pt-24 sm:pb-24'
                : 'fixed bottom-0 inset-x-0 sm:flex sm:items-center sm:justify-center',
            )
          : 'hidden'
      }
    >
      <Transition
        show={isShowing}
        appear={false}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={classNames(
            'inset-0 transition-opacity',
            isLongModalMode ? 'absolute' : 'fixed',
          )}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
      </Transition>
      <Transition
        show={isShowing}
        appear={false}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          className={classNames(
            'bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:w-full sm:p-6',
            fullWidth ? 'sm:max-w-3xl' : 'sm:max-w-md',
            isLongModalMode ? 'mx-auto' : '',
          )}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          ref={ref}
        >
          <div>
            <div className="text-left">
              <h3 className="text-lg text-center leading-6 font-medium text-gray-900">
                {title}
              </h3>
            </div>
          </div>
          <div>{children}</div>
          {hideActionButtons ? (
            <></>
          ) : (
            <div className="mt-5 sm:mt-6">
              {onSubmit ? (
                <span className="flex gap-4">
                  <Button buttonColor="white" onClick={() => onClose()}>
                    Discard
                  </Button>
                  <Button buttonColor="indigo" onClick={() => onSubmit()}>
                    Save changes
                  </Button>
                </span>
              ) : (
                <span className="flex gap-4">
                  <Button
                    buttonColor={subtleCloseButton ? 'white' : 'indigo'}
                    onClick={() => onClose()}
                  >
                    Close
                  </Button>
                </span>
              )}
            </div>
          )}
        </div>
      </Transition>
    </div>
  );

  if (isLongModalMode) {
    return <div className="fixed inset-0 overflow-y-scroll z-30">{inner}</div>;
  }

  return inner;
};

export default Modal;
