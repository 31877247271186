import classNames from 'classnames';
import moment from 'moment';
import React, { Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';

import { gql, useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import PageLoader from '@frontend/components/PageLoader';

import config from '../../config';
import Layout from '../shared/Layout';
import { GET_CASE } from './queries';
import { CaseContext, CaseContextObject, CaseContextValues } from './useCaseContext';

const Root = React.lazy(() => import('./components/Root'));

const STEALTH_SESSION_STORAGE_KEY = 'assured$$GodStealthMode';

const IS_E2E_DEMO = window.location.search.indexOf('e2e=1') !== -1;
const IS_E2E_DEMO_INITIAL = window.location.search.indexOf('e2einit=1') !== -1;

export default function CaseView() {
  const { user } = useAuth0();
  const isGod = !!user?.['https://assured.claims/god'];

  const [caseContext, setCaseContext] = useState<CaseContextValues>({
    isGod,
    isGodStealthMode:
      !!window.localStorage[STEALTH_SESSION_STORAGE_KEY] || false,
    isE2EMode: IS_E2E_DEMO,
    perspective: null,
  });
  const contextValue: CaseContextObject = {
    isGod: isGod && !caseContext.isGodStealthMode,
    isGodStealthMode: caseContext.isGodStealthMode,
    isE2EMode: caseContext.isE2EMode,
    perspective: caseContext.perspective,
    setPerspective: p => setCaseContext(c => ({ ...c, perspective: p })),
    setGodStealthMode: p => {
      setCaseContext(c => ({ ...c, isGodStealthMode: p }));
      if (p) {
        window.localStorage[STEALTH_SESSION_STORAGE_KEY] = true;
      } else {
        delete window.localStorage[STEALTH_SESSION_STORAGE_KEY];
      }
    },
  };

  const { id } = useParams<{ id: string }>();
  const { loading, error, data } = useQuery(GET_CASE, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
    pollInterval: 30 * 1000, // refresh every 30s
  });

  // Delighted customer satisfaction survey
  useEffect(() => {
    if ('delighted' in window && config.enableNpsSurvey) {
      (window as any).delighted.survey({
        properties: {
          tenant: config.tenant,
          caseId: id,
        },
      });
    }
  }, []);

  return (
    <Layout
      navigation={[
        { href: '/cases', name: 'Intelligent Investigation' },
        {
          current: true,
          name: `${data?.case?.externalId || data?.case?.id || 'Loading...'}`,
        },
      ]}
    >
      {data?.case?.externalId ? (
        <Helmet>
          <title>#{data?.case?.externalId} | Assured ClaimView</title>
        </Helmet>
      ) : null}
      <Suspense
        fallback={
          <PageLoader
            style={{ minHeight: '80rvh' }}
            text="Loading ClaimView experience..."
          />
        }
      >
        {loading ? (
          <PageLoader
            style={{ minHeight: '80rvh' }}
            text="Fetching claim information..."
          />
        ) : (
          <div
            className={classNames(
              'mx-auto sm:px-6 lg:px-8',
              config.usePlatformMode ? 'py-2' : 'max-w-7xl py-6',
            )}
          >
            <div className="px-4 py-6 sm:px-0">
              {(() => {
                if (!id) {
                  return (
                    <div className="text-center font-bold text-gray-500">
                      {config.singleClaim
                        ? `Error: Invalid token provided. Please ensure you have clicked a valid link.`
                        : `Please enter a claim ID above to continue.`}
                    </div>
                  );
                }

                if (error || !data?.case) {
                  return (
                    <div className="text-center font-bold text-gray-500">
                      {error ? `Failed to load: ${error}` : 'Claim not found'}
                    </div>
                  );
                }

                let currentCase = data.case;
                if (IS_E2E_DEMO_INITIAL) {
                  currentCase = {
                    ...currentCase,
                    investigationScheduledToEndAt: moment()
                      .add(18, 'hours')
                      .toDate(),
                    contacts: currentCase.contacts
                      .map((c: any) => {
                        if (c.name === 'Lucy Doe') {
                          return null;
                        }
                        if (c.name !== 'Dan Smith') {
                          return { ...c, reportState: {} };
                        }
                        return c;
                      })
                      .filter((x: any) => !!x),
                  };
                }

                return (
                  <CaseContext.Provider value={contextValue}>
                    <Root currentCase={currentCase} />
                  </CaseContext.Provider>
                );
              })()}
            </div>
          </div>
        )}
      </Suspense>
    </Layout>
  );
}
