import { gql } from '@apollo/client';

const CASE_FRAGMENT = gql`
  fragment _Case on Case {
    id
    externalId
    status
    assignedTo
    externalType
    externalPlainTextDescription
    incidentOccurredAt
    incidentTimezone
    investigationBeganAt
    investigationScheduledToEndAt
    investigationReadyToDispatchAt
    investigationReadyForViewingAt
    assignedTo

    contacts {
      id
      name
      phoneNumber
      types
      source
      caseVehicle {
        id
        vin
        make
        model
        isCarrierInsuredVehicle
      }
      investigationOptions {
        shouldContact
        repairCoordinationEligible
      }
      reportState {
        firstContactedAt
        lastContactedAt
        firstEngagedAt
        lastEngagedAt
        submittedAt
      }
    }

    vehicles {
      id
      vin
      year
      make
      model
      licensePlate
      licensePlateState
      isCarrierInsuredVehicle
    }

    unifiedClaim

    extractedCoverageData
  }
`;

export const GET_CASE = gql`
  query GetCase($id: ID!) {
    case(id: $id) {
      ..._Case
    }
  }

  ${CASE_FRAGMENT}
`;

export const UPDATE_INVESTIGATION_SETTINGS = gql`
  mutation UpdateInvestigationSettings(
    $caseId: ID!
    $settings: [CaseContactInvestigationSettingsInput!]!
    $addNewContacts: [CaseContactAddNewInput!]!
  ) {
    updateInvestigationSettingsForCase(
      caseId: $caseId
      settings: $settings
      addNewContacts: $addNewContacts
    ) {
      ..._Case
    }
  }

  ${CASE_FRAGMENT}
`;

export interface UpdateInvestigationSettingsData {
  updateInvestigationSettingsForCase: {
    id: string;
    externalId: string;
  };
}

export const UPDATE_GOD_CONTROLS = gql`
  mutation UpdateGodControls($caseId: ID!, $controls: CaseGodControlsInput!) {
    updateGodControlsForCase(caseId: $caseId, controls: $controls) {
      ..._Case
    }
  }

  ${CASE_FRAGMENT}
`;

export const UPDATE_CASE_STATUS = gql`
  mutation UpdateInvestigationCaseStatus($caseId: ID!, $status: CaseStatus!) {
    updateInvestigationCaseStatus(caseId: $caseId, status: $status) {
      ..._Case
    }
  }

  ${CASE_FRAGMENT}
`;

export interface UpdateInvestigationSettingsData {
  updateInvestigationSettingsForCase: {
    id: string;
    externalId: string;
  };
}

export const GENERATE_ACCESS_LINKS = gql`
  mutation GenerateAccessLinks($caseId: ID!) {
    generateAccessLinks(caseId: $caseId) {
      name
      types
      link
    }
  }
`;

export const GET_EXTERNAL_DOCUMENT = gql`
  query GetExternalDocument($id: ID!) {
    case(id: $id) {
      externalDocumentContentBase64
    }
  }
`;

export const GET_COMPLIANCE_DOCUMENT = gql`
  query GetComplianceDocument($id: ID!) {
    case(id: $id) {
      complianceDocumentBase64
    }
  }
`;

export const GET_IMAGES_DOCUMENT = gql`
  query GetImagesDocument($id: ID!) {
    case(id: $id) {
      imagesDocumentBase64
    }
  }
`;

export const LOOKUP_PHONE_NUMBERS = gql`
  query LookupPhoneNumbers($phoneNumbers: [String!]!) {
    lookupPhoneNumbers(phoneNumbers: $phoneNumbers) {
      phoneNumber
      type
      carrierName
    }
  }
`;
