import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import Modal from '@common/components/Modal';

import config from '../../config';
import Header from '../shared/Header';
import Layout from '../shared/Layout';
import {
  DELETE_USER, EDIT_USER, GET_USERS, GetUserData, INVITE_USER, RESEND_INVITE_TO_USER
} from './Queries';

const GOD_DOMAIN = 'assured.claims';

export default function UserManagement() {
  const { user: currentUser } = useAuth0();
  const isAuthorized =
    currentUser?.['https://assured.claims/role'] === 'administrator';

  const { loading, error, data, refetch } = useQuery<GetUserData>(GET_USERS, {
    skip: !isAuthorized,
    onError: e => {
      window.alert(`Failed to load users: ${JSON.stringify(e)}`);
    },
  });
  const [inviteUser, { loading: loadingInvite }] = useMutation(INVITE_USER, {
    onCompleted: () => refetch(),
    onError: e => {
      window.alert(`Failed to invite user: ${JSON.stringify(e)}`);
    },
  });
  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted: () => refetch(),
    onError: e => {
      window.alert(`Failed to delete user: ${JSON.stringify(e)}`);
    },
  });
  const [resendInviteToUser] = useMutation(RESEND_INVITE_TO_USER, {
    onCompleted: () => {
      window.alert(`Invite resent!`);
    },
    onError: e => {
      window.alert(`Failed to resend invite: ${JSON.stringify(e)}`);
    },
  });
  const [editUser] = useMutation(EDIT_USER, {
    onCompleted: () => refetch(),
    onError: e => {
      window.alert(`Failed to edit user: ${JSON.stringify(e)}`);
    },
  });

  const onInviteUserSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const formData = new FormData(form);
    inviteUser({
      variables: {
        payload: {
          name: formData.get('name'),
          email: formData.get('email'),
          role: formData.get('role'),
        },
      },
    });

    form.reset();
  };

  const [showUserEditor, setShowUserEditor] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedUserRole, setSelectedUserRole] = useState<string | null>(null);
  const selectedUser = data?.adminClaimViewUsers.find(
    u => u.user_id === selectedUserId,
  );

  const inner = !isAuthorized ? (
    <div
      style={{ height: '80vh' }}
      className="flex flex-col items-center justify-center text-center"
    >
      <div className="text-gray-500 max-w-sm">
        You do not have access to manage users. Please contact your
        administrator.
      </div>
    </div>
  ) : (
    <>
      <Modal
        isShowing={showUserEditor}
        title="Manage user role"
        onClose={() => setShowUserEditor(false)}
        onSubmit={() => {
          editUser({
            variables: {
              id: selectedUserId,
              payload: { role: selectedUserRole },
            },
          });
          setShowUserEditor(false);
        }}
      >
        {selectedUser ? (
          <>
            <div className="my-4 text-sm text-center text-gray-600">
              Please choose a role for {selectedUser.name}.
            </div>
            {[
              {
                role: 'administrator',
                label: 'Administrator',
                description:
                  'Able to invite members, change roles of existing members, and revoke access in addition to capabilities available to a member.',
              },
              {
                role: 'user',
                label: 'Member',
                description:
                  'Able to view submitted claims in the ClaimView Dashboard and download claim files.',
              },
            ].map(({ role, label, description }, i) => (
              <div
                className={classNames(
                  i === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                  i === 1 ? 'rounded-bl-md rounded-br-md' : '',
                  selectedUserRole === role
                    ? 'bg-indigo-50 border-indigo-200 z-10'
                    : 'border-gray-200',
                  'relative border p-4 flex cursor-pointer focus:outline-none',
                )}
                onClick={() => setSelectedUserRole(role)}
              >
                <span
                  className={classNames(
                    selectedUserRole === role
                      ? 'bg-indigo-600 border-transparent'
                      : 'bg-white border-gray-300',
                    'h-4 w-4 flex-shrink-0 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
                  )}
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col">
                  <span
                    className={classNames(
                      selectedUserRole === role
                        ? 'text-indigo-900'
                        : 'text-gray-900',
                      'block text-sm font-medium',
                    )}
                  >
                    {label}
                  </span>
                  <span
                    className={classNames(
                      selectedUserRole === role
                        ? 'text-indigo-700'
                        : 'text-gray-500',
                      'block text-sm',
                    )}
                  >
                    {description}
                  </span>
                </div>
              </div>
            ))}
          </>
        ) : null}
      </Modal>
      <div className="mt-12 max-w-7xl mx-auto px-8 flex flex-col pb-12">
        {data?.adminClaimViewUsers ? (
          <>
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-100">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Role
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {data?.adminClaimViewUsers
                        .map(user => {
                          return {
                            ...user,
                            is_self: user.user_id === currentUser.sub,
                          };
                        })
                        .filter(
                          // Hide assured admin users, except from other assured users
                          currentUser.email?.indexOf(GOD_DOMAIN) !== -1
                            ? () => true
                            : user => user.email?.indexOf(GOD_DOMAIN) === -1,
                        )
                        .sort((a, b) => (a.is_self ? -1 : b.is_self ? 1 : 0))
                        .map(user => {
                          return (
                            <tr key={user.user_id}>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                  <div className="flex-shrink-0 h-10 w-10">
                                    <img
                                      className="h-10 w-10 rounded-full"
                                      src={user.picture}
                                    />
                                  </div>
                                  <div className="ml-4">
                                    <div className="text-sm font-medium text-gray-900">
                                      {user.name}
                                      {user.is_self ? ' (you)' : ''}
                                    </div>
                                    <div className="text-sm text-gray-500">
                                      {user.email}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div>
                                  {user.multifactor ? (
                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                      MFA enabled
                                    </span>
                                  ) : (
                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                      MFA not set up
                                    </span>
                                  )}
                                  {user.last_login ? (
                                    <span className="ml-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                                      Last login{' '}
                                      {moment(user.last_login).fromNow()}
                                    </span>
                                  ) : (
                                    <span className="ml-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                      Not yet logged in
                                    </span>
                                  )}
                                </div>
                                {user.last_ip ? (
                                  <div className="mt-1 text-xs font-medium text-gray-500">
                                    Latest IP address: {user.last_ip}
                                  </div>
                                ) : null}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
                                {user.role === 'user' ? 'member' : user.role}{' '}
                                {!user.is_self ? (
                                  <button
                                    className="ml-1 text-indigo-600 hover:text-indigo-900 focus:text-indigo-800 focus:outline-none"
                                    onClick={() => {
                                      setSelectedUserId(user.user_id);
                                      setSelectedUserRole(user.role);
                                      setShowUserEditor(true);
                                    }}
                                  >
                                    Edit
                                  </button>
                                ) : null}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                {user.is_self ? (
                                  <></>
                                ) : (
                                  <>
                                    {!user.last_login ? (
                                      <button
                                        className="mr-4 text-indigo-600 hover:text-indigo-900 focus:text-indigo-800 focus:outline-none"
                                        onClick={() =>
                                          resendInviteToUser({
                                            variables: { id: user.user_id },
                                          })
                                        }
                                      >
                                        Resend invite
                                      </button>
                                    ) : null}
                                    <button
                                      className="text-indigo-600 hover:text-indigo-900 focus:text-indigo-800 focus:outline-none"
                                      onClick={() => {
                                        if (
                                          window.confirm(
                                            `Are you sure you would like to delete "${user.email}"? They will no longer be able to log in.`,
                                          )
                                        ) {
                                          deleteUser({
                                            variables: { id: user.user_id },
                                          });
                                        }
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="mt-9">
              <form
                className="shadow sm:rounded-md sm:overflow-hidden"
                onSubmit={onInviteUserSubmit}
              >
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Invite a new user
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      As an Administrator, you can invite new users to join{' '}
                      {config.tenant}. Once invited, new users must follow the
                      steps in the invitation email to set up their account.
                    </p>
                  </div>

                  <div className="grid sm:grid-cols-9 grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Full name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        required
                        className="mt-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        required
                        className="mt-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="role"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Role
                      </label>
                      <select
                        id="role"
                        name="role"
                        required
                        className="mt-1 block w-full py-2 px-3 border bg-white rounded shadow text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      >
                        <option value="user">Member</option>
                        <option value="administrator">Administrator</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:shadow-outline-indigo"
                    disabled={loadingInvite}
                  >
                    {loadingInvite
                      ? 'Sending invite email...'
                      : 'Send invite email'}
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : (
          <div className="text-center font-medium text-gray-600">
            Loading users...
          </div>
        )}
      </div>
    </>
  );

  if (config.usePlatformMode) {
    return (
      <Layout navigation={[{ current: true, name: 'Users' }]}>{inner}</Layout>
    );
  }

  return (
    <>
      <Header />
      {inner}
    </>
  );
}
