import 'rc-slider/assets/index.css';
import './EmojiSlider.css';

import Slider from 'rc-slider';
import React, { useState } from 'react';

interface EmojiSliderProps {
  /** A list of emoji's that will be displayed on the slider. Group from lowest to highest  */
  /**@example 😡 😐 😀 😍 */
  emojiList: string[];
  /** Points at which the emoji will switch on the slider */
  stopPoints: number[];
  minValue: number;
  maxValue: number;
  step?: number;
  /** Mutation that is called when user clicks the continue button */
  onSubmit: (value: number) => void;
}

type CustomSlideArgs = {
  className: string;
  prefixCls?: string;
  vertical?: boolean;
  offset: number;
  value: number;
  dragging?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  reverse?: boolean;
  index: number;
  tabIndex?: number;
  ariaLabel: string;
  ariaLabelledBy: string;
  ariaValueTextFormatter: string;
  style?: React.CSSProperties;
  ref?: React.Ref<any>;
};

const EmojiSlider: React.FC<EmojiSliderProps> = ({
  emojiList,
  stopPoints,
  minValue,
  maxValue,
  step,
  onSubmit,
}) => {
  const [rating, setRating] = useState<number>(maxValue);

  const customSlideHandle = (emojiList: string[], stopPoints: number[]) => {
    return (props: CustomSlideArgs) => {
      const style = {
        left: `${props.offset}%`,
      };

      const currentEmoji =
        emojiList[stopPoints.findIndex(threshold => props.value < threshold)];

      return (
        <div
          style={style}
          className="w-10 h-10 absolute cursor-pointer -ml-8 -mt-11 text-6xl"
        >
          {currentEmoji}
        </div>
      );
    };
  };
  const sliderHandle = customSlideHandle(emojiList, stopPoints);

  return (
    <div className="EmojiSlider-container flex flex-col items-center justify-center w-full mt-8">
      <Slider
        trackStyle={{ backgroundColor: '#76a9fa ', height: 10 }}
        min={minValue}
        max={maxValue}
        defaultValue={maxValue}
        handle={sliderHandle}
        step={step || 1}
        onAfterChange={sliderRating => setRating(sliderRating)}
        railStyle={{ backgroundColor: '#e9e9e9', height: 10 }}
      />
      <button className="btn btn-blue mt-12" onClick={() => onSubmit(rating)}>
        Continue
      </button>
    </div>
  );
};

export default EmojiSlider;
