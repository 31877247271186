import { gql } from '@apollo/client';

const USER_FRAGMENT = gql`
  fragment _User on AdminClaimViewUser {
    user_id
    name
    email
    picture
    multifactor
    role
    last_login
    last_ip
  }
`;
export const GET_USERS = gql`
  query GetAdminClaimViewUsers {
    adminClaimViewUsers {
      ..._User
    }
  }

  ${USER_FRAGMENT}
`;

export const INVITE_USER = gql`
  mutation InviteAdminClaimViewUser(
    $payload: AdminClaimViewUserInvitePayload!
  ) {
    inviteAdminClaimViewUser(payload: $payload) {
      ..._User
    }
  }

  ${USER_FRAGMENT}
`;

export const RESEND_INVITE_TO_USER = gql`
  mutation ResendAdminClaimViewUserInvite($id: String!) {
    resendAdminClaimViewUserInvite(id: $id) {
      ..._User
    }
  }

  ${USER_FRAGMENT}
`;

export const DELETE_USER = gql`
  mutation DeleteAdminClaimViewUser($id: String!) {
    deleteAdminClaimViewUser(id: $id) {
      success
    }
  }
`;

export const EDIT_USER = gql`
  mutation EditAdminClaimViewUser(
    $id: String!
    $payload: AdminClaimViewUserEditPayload!
  ) {
    editAdminClaimViewUser(id: $id, payload: $payload) {
      ..._User
    }
  }

  ${USER_FRAGMENT}
`;

export interface GetUserData {
  adminClaimViewUsers: {
    user_id: string;
    name: string;
    email: string;
    picture: string;
    multifactor?: string[]; // MFA methods
    role: string;
    last_login: string;
    last_ip: string;
  }[];
}
