import React, { useContext } from 'react';

export interface CaseContextValues {
  isGod: boolean;
  isGodStealthMode: boolean;
  isE2EMode: boolean;
  perspective: string | null;
}

export interface CaseContextObject extends CaseContextValues {
  setPerspective: (p: string | null) => void;
  setGodStealthMode: (p: boolean) => void;
}

const defaultCaseContext: CaseContextObject = {
  isGod: false,
  isGodStealthMode: false,
  isE2EMode: false,
  perspective: null,
  setPerspective: () => {},
  setGodStealthMode: () => {},
};

export const CaseContext =
  React.createContext<CaseContextObject>(defaultCaseContext);

export const useCaseContext = () => {
  const context = useContext(CaseContext);
  return context;
};
