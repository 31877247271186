import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

const ReactVideoRecorder = require('react-video-recorder').default;

import './VideoRecorder.css';

interface VideoRecorderProps {
  className?: string;
  onSubmit: (blob: Blob) => void;
}

const VideoRecorder: React.FC<VideoRecorderProps> = ({
  className,
  onSubmit,
}) => {
  const recorderRef = useRef<typeof ReactVideoRecorder | null>(null);
  const [readyBlob, setReadyBlob] = useState<Blob | null>(null);
  const [retakeAt, setRetakeAt] = useState<Date | null>(null);

  useEffect(() => {
    setReadyBlob(null);
    recorderRef.current?.handleStopReplaying();
  }, [retakeAt]);

  return (
    <div className={classNames('VideoRecorder relative', className)}>
      <ReactVideoRecorder
        ref={(r: typeof ReactVideoRecorder) => {
          recorderRef.current = r;
        }}
        renderDisconnectedView={() => null}
        timeLimit={15000}
        isOnInitially={!!retakeAt}
        onRecordingComplete={(videoBlob: any) => {
          setReadyBlob(videoBlob);
        }}
      />
      {readyBlob ? (
        <div
          className="absolute flex items-center justify-center w-full"
          style={{ bottom: '1rem' }}
        >
          <button
            className="btn btn-subtle text-white"
            onClick={() => {
              setRetakeAt(new Date());
            }}
          >
            Retake
          </button>
          <button className="btn btn-blue" onClick={() => onSubmit(readyBlob)}>
            Upload
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default VideoRecorder;
