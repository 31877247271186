import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';

enum ButtonType {
  BUTTON = 'button',
  RESET = 'reset',
  SUBMIT = 'submit',
}

interface ButtonProps {
  /** Color of the button */
  buttonColor: 'gray' | 'indigo' | 'white' | 'blue';
  /**Child components for the buttons, generally text */
  children: React.ReactNode;
  /** Optional classname for the buttons container class */
  containerClassName?: string;
  /** onClick handler for the button */
  onClick: MouseEventHandler<HTMLButtonElement>;
  /** Disable interaction */
  disabled?: boolean;
  /** Type for button component */
  type?: ButtonType;
}

const Button: React.FC<ButtonProps> = ({
  buttonColor = 'gray',
  children,
  containerClassName = '',
  onClick,
  disabled,
  type = ButtonType.BUTTON,
}) => {
  const buttonContainerClasses = classNames(
    'inline-flex justify-center items-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5',
    {
      'bg-gray-500 hover:bg-gray-400 focus:border-gray-700 focus:shadow-outline-gray text-white':
        buttonColor === 'gray',
      'bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo text-white':
        buttonColor === 'indigo' && !disabled,
      'bg-indigo-400 text-white': buttonColor === 'indigo' && disabled,
      'bg-white hover:bg-gray-50 border-gray-300 focus:border-gray-400 focus:shadow-outline-indigo text-gray-700':
        buttonColor === 'white',
      'bg-blue-500 hover:bg-blue-400 focus:border-blue-600 focus:shadow-outline-blue text-white':
        buttonColor === 'blue',
      'opacity-75 select-none cursor-not-allowed': !!disabled,
    },
    containerClassName,
  );
  return (
    <button
      className={buttonContainerClasses}
      type={type}
      onClick={!disabled ? onClick : undefined}
    >
      {children}
    </button>
  );
};

export default Button;
