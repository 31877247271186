// TODO @{lbarrick}: Once we get common working, this should be moved to common
import React, { useState } from 'react';

interface StarRatingProps {
  /** Mutation that gets submitted once user clicks continue */
  onSubmit: (value: number) => void;
  /** List of values that contains the weight values of each star rating */
  valueList: number[];
}

const StarRating: React.FC<StarRatingProps> = ({ onSubmit, valueList }) => {
  const [rating, setRating] = useState<number>(valueList[valueList.length - 1]);
  const [hover, setHover] = useState<number>(0);
  const [starRating, setStarRating] = useState<number>(0);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-row mb-2">
        {valueList.map((value, idx) => (
          <button
            className="text-5xl border-none focus:outline-none"
            role="button"
            style={{ color: '#ff9933' }}
            onClick={() => {
              setStarRating(idx + 1);
              setRating(value);
            }}
            onMouseEnter={() => setHover(idx + 1)}
            onMouseLeave={() => setHover(starRating)}
          >
            {(hover || starRating) >= idx + 1 ? '★' : '☆'}
          </button>
        ))}
      </div>
      <button
        className={
          starRating === 0 ? 'btn btn-disabled btn-blue' : 'btn btn-blue'
        }
        disabled={starRating === 0}
        onClick={() => onSubmit(rating)}
      >
        Continue
      </button>
    </div>
  );
};

export default StarRating;
